import React from 'react'
import { CreateNavigator } from './../components/CreateNavigator'

export function Create () {
  return (
    <>
      <CreateNavigator />
    </>
  )
}
